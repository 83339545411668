import {Place, Element} from '@wix/ambassador-seating-plans/types'
import {FeeType} from '@wix/events-types'
import {Plan} from '@wix/seating-viewer'
import {getFormattedPrice} from '@wix/wix-events-commons-statics'
import React, {useState} from 'react'
import Plus from 'wix-ui-icons-common/on-stage/Plus'
import Minus from 'wix-ui-icons-common/on-stage/Minus'
import {IconButton} from 'wix-ui-tpa/IconButton'
import {useViewBox} from '../../../hooks/viewbox'
import {Modal} from '../modal'
import {SeatingPlanProps, SelectedSeat} from './interfaces'
import s from './seating-plan.scss'
import {Footer} from './footer'

export const SeatingPlan = ({plan, tickets}: SeatingPlanProps) => {
  const {viewBox, zoom, changeSvgViewBox, onPointerDown, onPointerMove, onPointerUp} = useViewBox(plan)
  const [selectedTicket, setSelectedTicket] = useState(null as SelectedSeat)
  const zoomOutDisabled = zoom === 0.2

  const onSeatClick = (seat: Place, categoryId: number, definition: Element) => {
    const ticketDefId = plan.categories.find(category => category.id === categoryId).externalId
    const ticketDefinition = tickets.find(ticketDef => ticketDef.id === ticketDefId)
    const sectorTitle = plan.sectors.find(sector => definition.sectorId === sector.id)?.title ?? ''

    setSelectedTicket({
      sector: sectorTitle,
      seat: seat.label,
      row: definition.title,
      ticketPrice: getFormattedPrice(Number(ticketDefinition.price.value), ticketDefinition.price.currency),
      fees: ticketDefinition.wixFeeConfig.type === FeeType.FEE_ADDED_AT_CHECKOUT ? 'Fee' : '',
      ticketName: ticketDefinition.name,
    })
  }

  return (
    <Modal skin="grey">
      <div
        style={{touchAction: 'none'}}
        className={s.container}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        onPointerLeave={onPointerUp}
        onPointerMove={onPointerMove}
      >
        <div>
          <Plan plan={plan} svgViewBox={viewBox} onSeatClick={onSeatClick} />
        </div>
        <div className={s.controls}>
          <IconButton className={s.control} onClick={() => changeSvgViewBox({deltaScale: 0.2})} icon={<Plus />} />
          <IconButton
            className={s.control}
            disabled={zoomOutDisabled}
            onClick={zoomOutDisabled ? undefined : () => changeSvgViewBox({deltaScale: -0.2})}
            icon={<Minus />}
          />
        </div>
        <Footer selectedTicket={selectedTicket} />
      </div>
    </Modal>
  )
}
