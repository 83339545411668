import React from 'react'
import classNames from 'classnames'
import X from 'wix-ui-icons-common/X'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {getWixAdsHeight} from '../../../utils/ui'
import {ModalProps} from './interfaces'
import s from './modal.scss'

export const Modal: React.FC<ModalProps> = ({children, t, closeModal, skin}) => {
  const bannerHeight = getWixAdsHeight()
  const grey = skin === 'grey'

  return (
    <div
      className={classNames(s.container, {[s.grey]: grey})}
      style={{marginTop: bannerHeight, paddingBottom: (grey ? 0 : 52) + bannerHeight}}
    >
      <button
        className={classNames(s.closeButtonX, 'a11yOutline')}
        data-hook={DH.CLOSE_BUTTON}
        aria-label={t('accessibility:a11y.close')}
        onClick={() => closeModal()}
      >
        <X className={s.iconX} />
      </button>
      {children}
    </div>
  )
}
