import * as React from 'react'
import {Button} from 'wix-ui-tpa/Button'
import s from './footer.scss'
import {FooterProps} from '.'

export const Footer = ({selectedTicket}: FooterProps) => (
  <div className={s.footer}>
    {selectedTicket ? (
      <div>
        <div style={{display: 'flex'}}>
          <LabelWithValue label="Sector" value={selectedTicket.sector} />
          <LabelWithValue label="Row" value={selectedTicket.row} />
          <LabelWithValue label="Seat" value={selectedTicket.seat} />
        </div>
        <div>{selectedTicket.ticketName}</div>
        <div>{selectedTicket.ticketPrice}</div>
        <div>{selectedTicket.fees}</div>
      </div>
    ) : (
      <>
        <div>Select tickets on seating map</div>
        <div className={s.buttons}>
          <Button>Tickets (0)</Button>
          <Button>Checkout</Button>
        </div>
      </>
    )}
  </div>
)

const LabelWithValue = ({label, value}: {label: string; value: string}) => {
  return value ? (
    <div>
      <div>{label}</div>
      <div>{value}</div>
    </div>
  ) : null
}
